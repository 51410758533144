import React, { Component } from "react";
import PropTypes from "prop-types";
import Router from "next/router";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  dialogPaper: {
    minWidth: "50vw",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: 20,
  },
  withMobileDialog: "xs",
});

const logo = "/static/images/Asistensi_logotipo.svg";

class ModalDisclaimer extends Component {
  render() {
    const { fullScreen, classes, handleClose, open, width } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth="sm"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        fullScreen={width < 600 ? true : false}
        withMobileDialog="sm"
      >
        <style global>
          {`
            .MuiPaper-rounded {
              border-radius: 25px;
            }
            .MuiDialogContent-root {
              overflow-y: hidden;
            }
          `}
        </style>

        <DialogTitle>
          <div className="flex items-center justify-center">
            <img
              alt="Logo Asistensi"
              src={logo}
              className="w-auto"
              style={{ height: 50, marginTop: 10, marginBottom: 10 }}
            />
          </div>
          <div className="flex items-center justify-center">
            <p>
              <span
                className="font-poppins font-bold text-sm md:text-2xl text-purple-90"
              >
                A toda la comunidad{" "}
              </span>
              <span
                className="font-poppins font-bold text-sm md:text-2xl text-pink-500"
              >
                {" "}
                asistensi
              </span>
            </p>
          </div>
          <div className="flex items-center justify-center">
            <span
              style={{
                color: "#200944",
              }}
              className="font-poppins font-bold text-xs text-center"
            >
              Reestructuración de empresa y cambios en su organización
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.03)",
            }}
            className="box-border overflow-y-scroll mx-2 h-full md:h-48 p-6 rounded-2xl mb-8 bg-gray-250 text-xs"
          >
            <p className="font-poppins font-normal">
              Nos dirigimos a toda la comunidad <span className="text-pink-500 font-bold">asistensi</span>, para informarles sobre un cambio empresarial que está teniendo lugar en nuestra organización. Como es de conocimiento público, los mercados financieros internacionales han estado experimentando una gran inestabilidad en los últimos meses. A pesar de los resultados operativos globales que hemos obtenido en los últimos años, nos hemos visto en la necesidad de reestructurar nuestra organización para continuar dando acceso a la salud, ahora con un alcance global reducido.
            </p>
            <p className="mt-4 font-poppins font-normal">
              En Venezuela y República Dominicana seguimos operando con normalidad, orientados por nuestro propósito. En el caso de Guatemala, Honduras, Filipinas y México, este cambio implica que no comercializaremos nuevos planes de salud, aunque continuaremos trabajando con cada uno de los afiliados de estos países, prestando nuestros servicios de forma regular y honrando nuestras obligaciones con clientes y proveedores.

            </p>
            <p className="mt-4 font-poppins font-normal">
              Como organización, reiteramos nuestro compromiso de seguir trabajando para transformar el sistema de salud y generar un impacto positivo en la sociedad. Nuestra meta no cambia, cambia nuestra manera de organizarnos y abordar tan importante prioridad.
            </p>
            <p className="mt-4 font-poppins font-normal pb-6">
              Durante este proceso agradecemos a todos su comprensión y apoyo, seguiremos contribuyendo al bienestar de la sociedad desde nuestro ámbito de acción.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button>
            <span
              style={{
                position: "absolute",
                top: 10,
                right: 25,
                color: "#FF2252",
                fontSize: 18,
              }}
              className="font-poppins"
              onClick={handleClose}
            >
              X
            </span>
          </button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(ModalDisclaimer)
);
