import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import TrustPilot from '../../trustPilot/TrustPilot';
import ModalDisclaimer from '../ModalDisclaimer';
import useWindowSize from '../../hooks/useWindowSize';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;

const bgHeader = `${serverUrlImages}/RD/familia-republica-dominicana.png`;
const sillhoutte = '/images/header-silhouette.svg';

const MainHeader = ({ element, goToHomeQuotation }) => {

	const [country, setCountry] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const size = useWindowSize();

	const onCloseModal = () => setShowModal(false)
	const disabledBtn = true;

	useEffect(() => {
		fetch('https://ipapi.co/json/')
		.then(response => response.json())
		.then(data => {
		  setCountry(data.country_name)
		})
		.catch(error => {
		  console.error('Error:', error);
		});
		setShowModal(true)
	}, [])

	return (
		<>
			<div
				data-testid="mainHeader"
				className="mt-20 bg-gray-100 md:mt-10 w-full relative flex p-6 md:p-0"
				ref={element}
			>
				<div
					className="h-full w-full flex flex-col md:w-2/3 lg:w-2/3 md:z-10 md:absolute bg-cover bg-no-repeat bg-right bg-bottom justify-center"
					style={{ backgroundImage: `url('${sillhoutte}')` }}
				>
					<div className="w-full flex flex-col py-12 sm:py-16 max-w-90 mx-auto md:max-w-60 md:mx-20 lg:max-w-70">
						<h1 className="font-poppins font-bold text-purple-500 text-3xl md:text-2xl lg:text-4.5xl xl:text-5xl leading-snug md:z-10">
						Cuida la salud de los tuyos en República Dominicana con nuestros planes de salud accesibles
						</h1>
						<p className="text-base lg:text-xl font-poppins leading-relaxed text-purple-500 mt-6 md:z-10">
						Protégelos estés donde estés frente a los malestares del día a día, las enfermedades comunes y las emergencias médicas con <b className="font-poppins text-pink-500">asistensi</b>.
						</p>
						<div className="flex justify-between sm:justify-start mt-8">
							<button
								className={`white-btn mr-2 ${disabledBtn ? 'cursor-not-allowed opacity-50' : ''}`}
								onClick={() => goToHomeQuotation()}
								disabled={disabledBtn}
								
							>
								<span className="sm:hidden">Quiero cotizar</span>
								<span className="sm:inline hidden">Quiero cotizar</span>
							</button>
							<Link
								href={{
									pathname: '/affiliation',
									query: { origin: 'affiliate' }
								}}
								as="/comenzar-afiliacion"
							>
								<button className={`primary-button ml-2 ${disabledBtn ? 'cursor-not-allowed opacity-50' : ''}`} 
										disabled={disabledBtn}>
									<span className="sm:hidden">Contratar ahora</span>
									<span className="sm:inline hidden">Contratar ahora</span>
								</button>
							</Link>
						</div>
						<div className="absolute bg-white py-4 px-2 bottom-0 right-1 md:hidden">
							<TrustPilot>
								<div class="trustpilot-widget" data-locale="es-ES" data-template-id="5406e65db0d04a09e042d5fc" data-businessunit-id="62bd917864b947c942a581de" data-style-height="24px" data-style-width="100%" data-theme="light" data-stars="4,5" data-font-family="Poppins" data-text-color="#200844">
									<a href="https://es.trustpilot.com/review/asistensi.com.do" target="_blank" rel="noopener">Trustpilot</a>
								</div>
							</TrustPilot>
						</div>
					</div>
				</div>
				<div className="justify-end hidden md:flex w-full">
					<div className="w-full justify-end flex overflow-hidden">
						<img
							src={bgHeader}
							alt="Seguro de emergencias médicas en República Dominicana"
							name="seguro-emergencias-medicas-republica-dominicana.jpg"
							className="md:w-3/5 lg:w-1/2 h-auto md:pl-32 lg:pl-20"
						/>
					</div>
					<div className="absolute bg-white py-4 px-2 bottom-0 right-1">
						<TrustPilot>
							<div
								class="trustpilot-widget"
								data-locale="es-ES"
								data-template-id="5419b732fbfb950b10de65e5"
								data-businessunit-id="62bd917864b947c942a581de"
								data-style-height="24px"
								data-style-width="100%"
								data-theme="light"
								data-stars="4,5"
								data-font-family="Poppins"
								data-text-color="#200844">
								<a href="https://es.trustpilot.com/review/asistensi.com.do" target="_blank" rel="noopener">
									Trustpilot
								</a>
							</div>
						</TrustPilot>
					</div>
				</div>
				{/* {
					showModal && (
						<ModalDisclaimer
							open={showModal}
							handleClose={onCloseModal}
							width={size.width}
						/>
				)
				} */}
			</div>
		</>
	);
};

export default MainHeader;
